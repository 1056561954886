// Try to get any card links
const cardLinks = document.querySelectorAll( '.card-link' );

// If there are card links
if ( cardLinks.length > 0 ) {

  // Loop through them
  cardLinks.forEach( function( cardLink ) {

    // Add a click event listener
    cardLink.addEventListener( 'click', function( event ) {

      // If the meta key (command key on Mac, Windows key on, well, Windows) is not being pressed
      if ( ! event.metaKey ) {

        // Change the URL to the patch's link
        document.location.href = cardLink.querySelector( 'a' ).getAttribute( 'href' );

      // Otherwise the meta key is being pressed
      } else {

        // Open the patch's link in a new tab
        window.open( cardLink.querySelector( 'a' ).getAttribute( 'href' ), '_blank' );

      }

    } );

    // Add the clickable class
    cardLink.classList.add( 'clickable' );
  } );
}
