const imageCredit = document.querySelector( '#image-credit' );

if ( imageCredit ) {

  document.querySelector( '#image-credit .credit-heading' ).addEventListener( 'click', function( event ) {

    imageCredit.classList.toggle( 'is-active' );

  } );

}
